window.APP_CONFIG = {
  TERMS_AND_CONDITIONS_URL: 'https://www.quicktrials.com/terms/',
  PRIVACY_POLICY_URL: 'https://www.quicktrials.com/privacy-policy/',
  WEB_APP_TUTORIAL_URL: '/documents/QuickTrials WebApp Tutorial (Ver2.1).pdf',
  MOBILE_APP_TUTORIAL_URL: '/documents/QuickTrials Mobile App Tutorial (Ver2.1).pdf',
  SHOW_TOUR: true,
  DEFAULT_ANONYMIZATION: 'visible',
  TRIAL_FIELDS_SPECIAL: false,
  PRIMARY_COLOR: '#689F38',
  PRIMARY_COLOR_LIGHT: '#AED581',
  ACCENT_COLOR: '#7C4DFF',
  ENABLE_DIM_DISABLE: true,
  ENABLE_REPORTS: false,
  FORCE_TRIAL_RESET: false,
  CUSTOM_VARIETY_REQUEST_URL: '',
  AUTO_NAME_TRIALS: false,
  SHOW_TPP: false,
  SHOW_REGISTRATIONS: false,
  USE_TPP_MEETING_NAMING: false,
  CROP_VARIETY_REQUIRED_ORIGIN: false,
  CAN_SEE_PRODUCERS: false,
  CAN_REMOVE_RCBD_PLOT: true,
  RESTRICT_OBSERVATIONS_TO_LIBRARY: false,
  MM_LIMIT_TO_REQUIRED: false,
  AUTO_CV_CODES: false,
  CONFIRM_SV_DAYS: false,
  ALLOW_PASSWORD_LOGIN: true,
  SHOW_GEOGRAPHIC_ZONES: false,
};
